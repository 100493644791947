import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');

  const sendMessageToKindroid = async () => {
    try {
      const result = await axios.post('https://api.kindroid.ai/v1/send-message', {
        ai_id: 'EzVwxk3PEAnjptYGlGhT',
        message: message,
      }, {
        headers: {
          'Authorization': 'Bearer kn_d871240d-cbc9-4740-b0c5-e288a7313c64',
        }
      });
      setResponse(result.data.response); // Handle the response accordingly
    } catch (error) {
      console.error('Error sending message to Kindroid:', error);
    }
  };

  return (
    <div className="App">
      <div className="code-ide">
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Write your code or message here"
        />
        <button onClick={sendMessageToKindroid}>Send to Kindroid</button>
      </div>
      <div className="kindroid-chat">
        <h3>Kindroid Response:</h3>
        <p>{response}</p>
      </div>
    </div>
  );
}

export default App;
